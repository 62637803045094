@keyframes drop-down {
  0% {
    transform: translateX(-50%) translateY(-100vh);
  }

  50% {
    transform: translateX(-50%) translateY(-100vh);
  }

  100% {
    transform: translateX(-50%) calc(translateY(0) - 50%);
  }
}

body {
  margin: 0;
  overflow: hidden;
}

.App {
  position: relative;
  overflow: hidden;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App-body {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-navbar {
  background-color: #FFFFFF;
  border-bottom: 0px dashed #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.navbar-brand {
  color: #000000 !important;
  padding: 5px 10px !important;
  margin: 5px 0px !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
}

#trash {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 0;
  color: #FFFFFF;
  background-color: #C1121F;
  padding: 8px 10px !important;
  border: 0px solid #000000;
  font-size: 2rem !important;
}

#bag {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 0;
  color: #FFFFFF;
  background-color: #669BBC;
  padding: 8px 10px !important;
  border: 0px solid #000000;
  font-size: 2rem !important;
}

#cart {
  color: #000000 !important;
  padding: 5px !important;
  margin: 0px !important;
  font-size: 1rem !important;
  z-index: 0;
}

#submit {
  color: #000000 !important;
  padding: 5px !important;
  margin: 0px !important;
  font-size: 1rem !important;
  z-index: 0;
}

.shirt-image {
  object-fit: cover;
  z-index: 2;
}

#navtainer {
  display: flex;
}

.nav-buttons-container {
  display: flex !important;
  justify-content: center !important;
}

@media (max-width: 768px) {
  #navtainer {
    flex-direction: column;
  }

  .nav-buttons-container {
    flex-direction: row !important;
    align-items: center;
  }
}

@media (min-width: 768px) {
  #navtainer {
    flex-direction: row;
  }

  .nav-buttons-container {
    flex-direction: column !important;
    align-items: flex-end;
  }
}